<template>
  <div>
    <div class="card-toolbar mb-5">
      <button v-if="$can('units.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('units.new_unit') }}
      </button>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name">{{ $t('name') }}</label>
              <input v-model="filters.name" type="text" id="name" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="abbreviation">{{ $t('abbreviation') }}</label>
              <input v-model="filters.abbreviation" type="text" id="abbreviation" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="notes">{{ $t('notes') }}</label>
              <input v-model="filters.notes" type="text" id="notes" class="form-control">
            </div>
            <div class="form-group col-md-6">
              <label for="name">{{ $t('status') }}</label>
              <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">

      <div class="card-body">
        <div class="d-flex justify-content-end mb-5">
          <!--                    <export-data :data-list="dataList" :file-name="fileName" :fields="json_fields"></export-data>-->
          <custom-export-data v-if="$can('units.export_data')" :data-list="dataList" :file-name="fileName" :fields="json_fields"></custom-export-data>
        </div>
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('units.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('units.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
<!--            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('units.delete')" @click="deleteunit(props.row)">mdi-delete</v-icon>-->
          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('units.unit')">
      <div class="row">
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('units.name') }}<span class="text-danger">*</span>
          </label>
          <input v-model="unit.name" type="text" id="name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
          <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.name[0] }}
                        </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('units.abbreviation') }}
          </label>
          <input v-model="unit.abbreviation" type="text" id="abbreviation" class="form-control" :class="validation && validation.abbreviation ? 'is-invalid' : ''">
          <span v-if="validation && validation.abbreviation" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.abbreviation[0] }}
                        </span>
        </div>
        <div class="col-md-6 mb-5 d-none">
          <label for="name">
            {{ $t('units.the_main') }}
          </label>
          <multiselect v-model="parent"
                       id="parent_id"
                       :placeholder="$t('units.the_main')"
                       label="name"
                       track-by="id"
                       :options="parent_list"
                       :multiple="false"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false">
          </multiselect>
          <span v-if="validation && validation.parent_id" class="fv-plugins-message-container invalid-feedback">
              {{ validation.abbreviation[0] }}
          </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('units.sub_value_total') }}<span v-if="unit.parent_id" class="text-danger">*</span>
          </label>
          <input v-model="unit.sub_value_total" type="number" min="1" id="name" class="form-control" :class="validation && validation.sub_value_total ? 'is-invalid' : ''">
          <span v-if="validation && validation.sub_value_total" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.sub_value_total[0] }}
                </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('inventories.type') }}
          </label>
          <select name="" id="is_active" v-model="unit.type" type="text" class="custom-select">
            <option value="" selected disabled>{{ $t('all') }}</option>
            <option v-for="(row, index) in type_list" :value="row.id" :key="index">{{ row.title }}</option>
          </select>
          <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
              {{ validation.type[0] }}
          </span>
        </div>
        <div class="col-md-6 mb-5">
          <div class="form-group">
            <label>{{ $t('units.status') }}:</label>
            <b-form-checkbox size="lg" v-model="unit.is_active" name="check-button" switch></b-form-checkbox>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="form-group">
            <label>{{ $t('units.default') }}:</label>
            <b-form-checkbox size="lg" v-model="unit.is_default" name="check-button" switch></b-form-checkbox>
          </div>
        </div>
        <div class="col-md-12 mb-5">
          <label for="notes">{{ $t('notes') }}</label>
          <textarea v-model="unit.notes" type="text" class="form-control" id="notes" :placeholder="$t('notes')"></textarea>
        </div>
      </div>


      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'

export default {
  name: "index",
  components: {},
  data() {
    return {
      unit: {
        name: '',
        abbreviation: '',
        sub_value_total: 1,
        notes: '',
        is_active: true,
        is_default: false,
        parent_id: null,
        type:null,
      },
      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        name: '',
        abbreviation: '',
        // sub_value_total: '',
        // parent_id: '',
        is_active: '',
        notes: ''
      },
      parent: null,
      // type:null,
      columns: ['name','type_name', 'created_at', 'status', 'actions'],
      validation: null,
      // checkedValue: 1,
      // checkedDefault: 0,
      mainRoute: 'settings/units',
      routeChangeStatus: 'settings/unit/change-status',
      isEditing: false,
      id: null,
      parent_list: [],
      type_list: [],
      dataList: [],
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
    }
  },
  watch: {
    'unit.name': function (val) {
      if (val) {
        this.unit.name = val.replace(/[\d]/g, "");
      }
    },
    parent: function (val) {
      if (val) {
        this.unit.parent_id = val.id;
      } else {
        this.unit.parent_id = null;
      }
    },
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('units.name')] = 'name';
      fields[this.$t('units.sub_value_total')] = 'sub_value_total';
      fields[this.$t('units.status')] = 'status';
      fields[this.$t('units.created_at')] = 'created_at';
      return fields;
    },
    fileName: function () {
      return this.$t('units.unit');
    },
    options: function () {
      let that = this;
      return {

        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('units.name'),
          type_name: that.$t('inventories.type'),
          sub_value_total: that.$t('units.sub_value_total'),
          created_at: that.$t('units.created_at'),
          status: that.$t('units.status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {

          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.units_management")}]);
    this.getParentList();
    this.getTypeList();
    // let that = this;
    // this.$root.$on('bv::modal::hidden', () => {
    //   this.afterSaveUnit();
    // });


  },
  methods: {
    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = '';
      this.filters.abbreviation = '';
      this.filters.is_active = '';
      this.filters.notes = '';

      this.$refs.table.refresh();
    },
    save() {
      if (this.isEditing) {
        this.updateUnit();
      } else {
        this.saveUnit();
      }
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(unit) {
      ApiService.delete(`${this.mainRoute}/${unit.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteunit(unit) {
      this.$confirmAlert('', this.actionDelete, unit);
    },

    showModal(data) {
      if (!data.id) {
        this.reset();
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data);
        this.$refs['modal'].show();
      }

    },
    hideModal() {
      this.reset();
      this.$refs['modal'].hide()
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn')
    },
    saveUnit() {
      ApiService.post(this.mainRoute, this.unit).then((response) => {
        this.$successAlert(response.data.message);
        this.hideModal();
        this.afterSaveUnit();
      }).catch((errors) => {
        this.$errorAlert(errors);
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },
    updateUnit() {
      ApiService.put(`${this.mainRoute}/${this.id}`, {
        id: this.id,
        ...this.unit,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.validation = null;
        this.hideModal();
        this.afterSaveUnit();
      })
          .catch((errors) => {
            this.$errorAlert(errors);
            this.validation = errors.response ? errors.response.data.errors : null;
          });
    },
    getData(data) {

      this.unit.name = data.name;
      this.unit.parent_id = data.parent_id;
      this.unit.type = data.type;
      this.unit.abbreviation = data.abbreviation;
      this.unit.is_active = data.is_active;
      this.unit.is_default = data.is_default;
      this.unit.sub_value_total = data.sub_value_total;
      this.unit.notes = data.notes;
      this.parent = data.parent_id? data.parent: {
        "id": null,
        "name": "none",
        "sub_value_total": null,
        "abbreviation": "none",
        "parent_id": null,
        "parent": {
          "id": null,
          "name": ""
        }
      };
    },
    reset() {
      this.unit = {
        name: '',
        abbreviation: '',
        sub_value_total: 1,
        notes: '',
        is_active: true,
        is_default: false,
        parent_id: null,
        type: null,
      };
      this.validation = [];
      this.isEditing = false;
      this.parent = null;
    },
    getParentList() {//filter
      // if (filter && filter.length > 2),{params: {name: filter}}unit_parents
      ApiService.get('base/dependency/units').then(response => {
        this.parent_list = response.data.data;
        this.parent_list.unshift({
          "id": null,
          "name": "none",
          "sub_value_total": null,
          "abbreviation": "none",
          "parent_id": null,
          "parent": {
            "id": null,
            "name": ""
          }
        })
      });
    },

     getTypeList() {
      ApiService.get('base/dependency/unit_types_list').then(response => {
        this.type_list = response.data.data;
      });
    },

    afterSaveUnit() {
      this.unit = {
        name: '',
        abbreviation: '',
        sub_value_total: 1,
        notes: '',
        is_active: true,
        is_default: false,
        parent_id: null,
        type: null,
      };
      this.validation = null;
      this.parent = null;
      // this.hideModal();
      this.getFetch();
      this.getParentList();
    }
  },
};
</script>
